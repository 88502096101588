import httpService from '../../../helpers/http.service'

export default {
    getAll(params) {
        return httpService.get(`liquidations/commissions`, {params})
    },
    get(id = null, params = {}) {
        return httpService.get(`liquidations/commissions${id ? '/' + id : '' }`, {params})
    },
    getNetPayment(id = null, params = {}) {
        return httpService.get(`liquidations/net-payment/commissions${id ? '/' + id : '' }`, {params})
    },
    typeAccount(id = null,   params = {}) {
        return httpService.patch(`liquidations/net-payment/${id}/status-pay`, params)
    },
    getReset(id = null, params = {}) {
        return httpService.get(`liquidations/commissions-resets${id ? '/' + id : '' }`, {params})
    },
    delete(id) {
      return   httpService.delete(`processed/enrollments/${id}`);
    },
    save(params) {
        return httpService.post(`liquidations/commissions/generate`, params);
    },
    reset(params) {
        return httpService.post(`liquidations/commissions/reset`, params);
    }
}
